.pg-main-sec {
  h1 {
    font-size: 60px;
  }

  .b-main-img {}

  .content-b {
    p {
      color: #5d5d5d;
      font-weight: bold;
    }
  }
}

.card-d-b {
  background: #FFFFFF;
  display: flex;
  margin-bottom: 24px;
  padding: 22px;
  border-radius: 12px;

  img {
    height: 70px;
    align-self: center;
  }

  .img-fit {
    object-fit: contain;
  }
}

.card-d-b-black {
  background: transparent;
  border: 1px solid white;
  color: white !important;
}
.banner-header .hint {
  font-size: 26px;
}

.total-wrapper {
  span {
    padding: 10px;
    border-radius: 12px;
    color: gray;
    font-size: 12px;
    background: #1e253d;
  }
}

.gray {
  color: gray;
}

.buy-wrapper {
  //width: 100%;
  padding: 10px;
  color: white;
  cursor: pointer;

  .card-name {
    background: rgb(21, 119, 255);
    padding: 2px 5px;
    border-radius: 4px;
  }
}

.sub-account {
  padding: 10px;
}
.button-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wallet-button-layout {
  position: relative;
  justify-content: center;
  display: flex;
}

.wallet-button-group {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -50px;
  gap: 60px;
}

.disable-wallet-button {
  background: linear-gradient(to right, rgb(189, 191, 193), rgb(226, 227, 228));
  border-radius: 15px;
  width: 50px;
  height: 50px;
}
.apexcharts-tooltip .apexcharts-tooltip-title,
.apexcharts-tooltip .apexcharts-tooltip-text {
  color: #000 !important;
}

.apexcharts-svg {
  background-color: unset !important;
}

.apexcharts-title-text {
  fill: gray;
}

.apexcharts-text {
  fill: gray;
}
.apexcharts-menu-item {
  color: #000 !important;
}
