.loading {
    margin-top: 25%;
    margin-left: 50%;
}

.linked-text,
.unlinked-text {
    font-size: 14px;
}

.linked-text {
    color: #00B050;
}

.unlinked-text {
    color: #FF0000;
}

.save-btn,
.save-btn:active {
    background: rgb(0, 32, 212);
    background: linear-gradient(261deg, rgba(0, 32, 212, 1) 65%, rgba(38, 114, 247, 1) 100%);
    color: whitesmoke !important;
}

.save-btn:hover {
    background: rgb(0, 32, 100);
    background: linear-gradient(261deg, rgba(0, 32, 100) 65%, 100%);
    color: whitesmoke !important;
}

.tier-wrapper {
    margin-top: 50px;
    max-width: 500px;
    height: 400px;
    display: flex;
    justify-content: center;
    background: rgb(189, 190, 193);
    background: radial-gradient(circle, rgba(189, 190, 193, 0.8449973739495799) 21%, rgba(233, 234, 236, 0.7441570378151261) 100%);
}

.tier-rectangle, .tier-rectangle-mobile {
    position: absolute;
    vertical-align: middle;
    border: 5px solid #E3E3E1;
    border-radius: 0 0 50px 50px;
    z-index: 1;
}


.tier-rectangle {
    width: 400px;
    height: 100px;
    margin-top: 20px;
    margin-left: 50px;
}

.tier-rectangle-mobile {
    width: 300px;
    height: 80px;
    margin-top: 10px;
    margin-left: 25px;
}

.tier {
    margin-top: 10px;
    text-align: center;
}

.tier-title {
    color: whitesmoke;
    font-weight: 800;
    font-size: 24px;
}

.verify-status {
    color: red;
}

.tier-card {
    margin: auto;
    border-radius: 20px;
    width: 480px;
    height: 380px;
}

.tier-card-body, .tier-card-body-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.tier-card-body {
    width: 400px;
    margin-top: 30px;
}

.tier-card-body-mobile {
    width: 300px;
    margin-top: 20px;
}

.tier-card-point {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.dot-not-verify, .dot-verify, .dot-fail {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

.dot-not-verify {
    background-color: #bbb;
}

.dot-verify {
    background-color: #06927E;
}

.dot-fail {
    background-color: #FF0000;
}

.verify-btn {
    margin-left: auto;
    margin-right: -30px;
}

.show-password {
    position: absolute;
    left: 100%;
    width: 300px;
}

.show-password-label {
    margin-top: 5px;
    margin-left: 5px;
    color: #B8B9BC;
    font-weight: 400;
}

.password-required-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    color: #B8B9BC;
}

.password-required {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.confirm-btn {
    margin-left: auto;
    margin-right: 28%;
}

.confirm-btn-mobile {
    margin-left: auto;
    margin-right: 10%;
}

.strength {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0;
    margin-top: 10px;
    margin-left: 20px;
    color: #B8B9BC;
}

.square {
    width: 80px;
    height: 20px;
    border: 1px solid #B8B9BC;
}

.low {
    background-color: red;
    border: 1px solid red !important;
}

.middle {
    background-color: orange;
    border: 1px solid orange !important;
}

.strong {
    background-color: green;
    border: 1px solid green !important;
}


.disable {
    cursor: no-drop !important;
}
