.thumbnail {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.thumbnail-cont {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.mv-h {
  height: 550px;
  overflow: auto;
}

.n-btn {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.n-btn:hover {
  background: #f9f9f9;
  cursor: pointer;
}

.n-title {
  background: rgba(255, 255, 255, 0.75);
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: inherit !important;
  backdrop-filter: blur(20px);
}

.n-title-top {
  background: rgba(255, 255, 255, 0.75);
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  backdrop-filter: blur(20px);
}

.btn-def {
  border-radius: 0;
}

.btn-def-activated {
  background: #1577FF;
  color: #FFFFFF !important;
}

.more-stories a:hover {
  opacity: 0.6;
}
.news-bg {
  background: url("../../assets/images/white-bg.jpg") no-repeat fixed right;
}