.small-txt {
    font-size: 11px;
    opacity: 0.5;
}
.switch-container {
    width: 71px;
    height: 38px;
    background-color: whitesmoke;
    border: solid 1px #1e253d;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.switch-button {
    width: 32px;
    height: 34px;
    background-color: rgb(0, 32, 212);
    border-radius: 5px;
    position: absolute;
    top: 1px;
    left: 4px;
    transition: transform 0.3s ease;
}

.switch-container.off {
    background-color: whitesmoke;
}

.switch-container.off .switch-button {
    transform: translateX(32px);
    background-color: #7b7b7b;
}

.switch-container.on {
    border: solid 1px #1e253d;
    background-color: whitesmoke;
}

.switch-container.on .switch-button {
    transform: translateX(0);
}