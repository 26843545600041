@import "~swiper/css";
@import "~swiper/css/pagination";

.news-caro {
  .swiper {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}


.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.card-box {
  height: auto;
  overflow: hidden;
  border-radius: 20px !important;
}

.ov-margin {
  margin-top: -80px;
}

.more-stories p {
  font-size: 16px;
}

.news-img {
  min-height: 120px;
  min-width: 120px;
  max-height: 120px;
  max-width: 120px;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
}


@media only screen and (max-width: 600px) {
  .main-slider {
    height: 330px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  /*.swp-text{
    color: #FFFFFF !important;
    position: absolute;
    top: 70px;
    z-index: 3;
    text-align: center;
    h1{
      font-size: 27px;
      color: #FFFFFF;
      font-weight: bold;
    }
    h5{
      font-size: 20px;
      font-weight: 300;
    }
  }*/
}


.media-container {
  .feature-img {
    width: 100%;
  }
}