.acc-box {
  padding: 10px;
  display: flex;
  border-radius: 8px;

  .acc-box-i {
    font-size: 38px;
  }
}

.acc-box:hover {
  background: #fafafa;
}

@media only screen and (max-width: 768px) {
  .vh-100 {
    height: auto !important;
  }
}