.bg-wt {
  background-attachment: fixed;
}

.sticky-top {
  position: sticky !important;
  top: 0;
}

.sub-menu-btn {
  text-align: left;
  // padding: 15px;
  font-size: 17px;
  display: flex;

  span {
    align-self: center;
  }
}

.user-img {
  height: 56px;
  width: 56px;
  border-radius: 50%;
}

.user-img-xl {
  height: 95px;
  width: 95px;
}

.post-cont {
  padding-top: 30px;

  .post {
    .profile {
      display: flex;
      font-size: 17px;

      .display-name {
        color: black;
        font-weight: 900;
      }

      .user-name,
      .posted-time {
        color: #939393;
        margin-left: 10px;
      }
    }

    .post-img {
      width: 100%;
      border-radius: 15px;
      cursor: pointer;
    }

    .post-content {
      white-space: pre-wrap;
    }
  }

  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.post-cont-comment {
  padding-top: 30px;

  .post {
    .profile {
      display: flex;
      font-size: 17px;

      .display-name {
        color: black;
        font-weight: 900;
      }

      .user-name,
      .posted-time {
        color: #939393;
        margin-left: 10px;
      }
    }

    .post-img {
      width: 100%;
      border-radius: 15px;
      cursor: pointer;
    }

    .post-content {
      white-space: pre-wrap;
    }
  }
}
.post-comments {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .post-comment-type {
    display: flex;

    span {
      font-size: 15px;
      align-self: center;
      color: #999999;
    }

    .material-symbols-outlined {
      font-size: 25px;
    }
  }
}

.create-post {
  .form-control {
    border: none;
    background: #f6f6f6;
    min-height: 100px;
    font-weight: 600;
    color: black;
    box-shadow: none;
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .sub-menu-btn {
    font-size: 14px;
  }
}

.filebutton input {
  display: none;
}

.image-preview {
  display: block;

  img {
    border-radius: 16px;
  }

  .remove-btn {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    height: 36px;
    width: 36px;
    padding: 6px;
    border-radius: 50%;
    display: block;
    margin-top: 31px;
    margin-right: 20px;
    z-index: 99;
    right: 0;
    cursor: pointer;
  }

  .remove-btn:hover {
    background: rgba(0, 0, 0, 0.34);
  }
}
