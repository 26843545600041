@import "~swiper/css";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";

.swiper {
  width: calc(100% + 20px);
  height: 100%;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.11) !important;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 20px !important;
}

.swiper-slide {
  .main-slider {
    img {
      display: block;
      height: 400px;
      width: calc(100% + 20px);
      object-fit: cover;
      object-position: right;
      margin-right: -10px;
      margin-left: -10px;
    }
  }
}

.swiper-slide {
  .sm-card {
    width: 100%;
  }
}

.main-slider {
  height: 400px;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.card-box {
  height: auto;
  overflow: hidden;
  border-radius: 20px !important;
  z-index: 9999999;
}

.ov-margin {
  margin-top: -80px;
}

.swp-text {
  color: black !important;
  position: absolute;
  top: 70px;
  z-index: 3;
  text-align: left;

  h1 {
    font-size: 44px;
    color: #FFFFFF;
    font-weight: bold;
  }

  h5 {
    font-size: 35px;
    font-weight: 300;
  }
}


@media only screen and (max-width: 768px) {
  .swiper {
    width: calc(100% + 0px);
  }

  .swiper-slide .lg-carousal {
    display: block;
    height: 400px;
  }

  .swiper-slide {
    .main-slider {
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .swp-text {
    color: #FFFFFF !important;
    position: absolute;
    top: 70px;
    z-index: 3;
    text-align: left;
    padding-left: 10px;

    h1 {
      font-size: 27px;
      color: #FFFFFF;
      font-weight: bold;
    }

    h5 {
      font-size: 20px;
      font-weight: 300;
    }
  }
}

.mb-caro {
  .swiper {
    height: 170px;
    border-radius: 13px;
  }
}

.m-btn {
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 20px 0px rgb(0 0 0 / 11%);

  p {
    font-weight: 900;
    font-size: 13px;
    white-space: nowrap;
  }

  .icon {
    color: #1577FF;
    font-size: 48px;
  }
}
