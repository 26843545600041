.info-wrapper {
  width: 100%;
  margin-left: 25px;
}

.nav-container {
  width: 100%;
  margin-left: 18%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  flex-grow: 0 !important;
}

.top-bar {
  background: #FFFFFF;
  padding-top: 7px;
  padding-bottom: 7px;
  color: black;
  font-size: 13px;
  font-weight: 600;

  p {
    font-size: 13px;
  }
}
.nav-link {
  font-weight: 400;
  cursor: pointer;
  padding: 0;
}

.header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-grow: 0 !important;
}

.user-menu,
.user-menu:hover,
.user-menu:focus {
  color: whitesmoke;
  padding: 0 !important;
}

.not-login-header {
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.user-header-settings {
  margin-left: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.login, .signup {
  border: 1px solid whitesmoke;
  padding: 2px 10px;
  border-radius: 12px;
  font-weight: 600 !important;
}

.signup {
  background-color: whitesmoke;
  color: #1677FF;
}
@media only screen and (max-width: 990px) {
  .nav-link {
    padding-right: 0 !important;
  }
  .navbar {
    position: fixed;
    top: 0;
    z-index: 4;
    width: 100%;
    .navbar-brand img {
      height: 28px !important;
    }
  }
  .h-ticker {
    width: 1000px;
    min-width: 100%;
    display: block;
    font-size: 13px;
    p {
      white-space: nowrap;
      margin-right: 40px;
    }
  }
  .top-bar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    position: fixed;
    top: 0;
    z-index: 4;
    overflow-x: auto;
    width: 100%;
  }
}
.m-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  padding: 12px;
  color: #6d6d6d;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.13);
  z-index: 99;
  span {
    font-size: 26px;
    display: block;
  }
  a {
    font-size: 12px;
    text-align: center;
    opacity: 1;
    font-weight: 600;
  }
}
.selected {
  color: #1000ff !important;
  opacity: 1 !important;
}

.custom-w {
  width: calc(100% - 110px);
}
.dropdown-content {
  padding: 0;
  color: black;
  overflow: hidden;
  a {
    padding: 15px;
  }
}

.currency-dropdown,
.notification-dropdown {
  height: 300px;
  overflow-y: scroll;
}

.dropdown-dark {
  background-color: #181C30;
  color: whitesmoke;
}

.currency-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.notification-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.notification-time {
  margin-right: 20px;
}

.notification-title {
  font-weight: 700;
  font-size: 20px;
  overflow: hidden;
}

.share-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-btn {
  width: 200px
}
.avatar-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
