.wrapper {
    margin-left: 40px;
    margin-right: 25px;
}
.title:hover,
.title:focus,
.title:active {
    color: whitesmoke;
}
.tab-text {
    font-size: 11px;
}

.menu-scroll {
    overflow-y: scroll;
}

.menu-scroll::-webkit-scrollbar,
.menu-scroll::-webkit-scrollbar-track,
.menu-scroll::-webkit-scrollbar-thumb {
    width: 0 !important;
    background: transparent !important;
}

.sub-menu-btn,
.sub-menu-btn-active {
    text-align: left;
    padding: 20px;
        font-size: 16px;
    font-weight: 400;
    display: flex;
    border-radius: 0;
    span {
        align-self: center;
    }
}

.sub-menu-btn-active,
.sub-menu-btn:not(.sub-menu-btn-activate):hover,
.sub-menu-btn-active-mobile,
.sub-menu-btn:not(.sub-menu-btn-active-mobile):hover {
    border-radius: 10px;
    background: rgb(0, 32, 212);
    background: linear-gradient(261deg, rgba(0, 32, 212, 1) 65%, rgba(38, 114, 247, 1) 100%);
    color: whitesmoke !important;
}

.sub-menu-btn-active-mobile {
    text-align: center;
    padding: 5px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        align-self: center;
    }
}
