.x-align {
    position: absolute;
    margin-top: 180px;
}

.stay-up-section {
    img {
        height: 600px;
        object-position: left;
        object-fit: cover;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {

    .stay-up-section {
        img {
            height: 230px;
        }

        .x-align {
            margin-top: 30px;
            padding: 12px;

            h1 {
                font-size: 22px;
            }

            h3 {
                font-size: 16px;
            }
        }
    }

    .x-align {
        position: absolute;
        margin-top: 180px;
    }
}