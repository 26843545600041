@media only screen and (max-width: 768px) {
  .vh-100 {
    height: auto !important;
  }

  .wt-box {
    box-shadow: none;
  }
}
.simplexform {
  background: #fafafa !important;
  color: #212529 !important;
}