.huobi-wallet-details {
  margin-left: 20px;
  padding: 5px;
  background: rgb(5 154 219);
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.huobi-button {
  background: linear-gradient(to right, rgb(5 152 216), rgb(2 139 187));
  border-radius: 15px;
  width: 50px;
  height: 50px;
}