.okx-wallet-details {
  margin-left: 20px;
  padding: 5px;
  background: #231f20;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.okx-button {
  background: linear-gradient(to right, rgb(95 95 97), rgb(35 31 32));
  border-radius: 15px;
  width: 50px;
  height: 50px;
}