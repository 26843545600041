.thread-history {
  background: transparent;
}

.th-history {
  font-weight: 400;
  color: #7b7b7b;
  border-bottom: none !important;
  padding-bottom: 20px;
}

.th-history:hover, .th-history:focus, .th-history:active {
  background-color: transparent;
}

.th-history-body {
  color: #585B5D;
  border: none;
}

.th-history-body td {
  font-weight: 400;
}

.table-body {
  margin-top: 10px;
}

.checkbox-history {
  font-weight: 400;
  color: #7b7b7b;
}

.search-dropdown-menu-exchange, .coins-dropdown {
  height: 300px;
  overflow-y: scroll;
}

.exchange-item {
  margin-left: 10px;
}

.coin-item:hover, .search-dropdown-exchange-item:hover {
  background-color: #1000ff;
  color: whitesmoke !important;
}

.search-wrapper {
  position: relative;
  display: inline-block;
}

.search-input {
  background-color: #E1E2E3;
  border: #E1E2E3;
  padding: 5px;
  color: #7b7c7d;
}

.search-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #7b7b7b;
}

.item-complete-status {
  color: #2F9E8F;
}

.item-status {
  color: #2530CF;
}

.td-exchange, .td-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.back-btn,
.back-btn:active {
  background: linear-gradient(261deg, rgba(0, 32, 212, 1) 65%, rgba(38, 114, 247, 1) 100%);
}

.back-btn:hover {
  background: rgba(0, 32, 100);
}

.coming-soon {
  margin-right: 10px;
  color: #1000ff;
}

