h3,
h1 {
  margin-top: 50px;
  font-weight: 600;
}

p {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 14px;
  }

  h3 {
    font-size: 18px;
  }

  h1 {
    font-size: 22px;
  }
}