.filter-date {
  color: blue;
  white-space: nowrap;
}

.filter-date-selected {
  padding: 5px;
  background: #1577FF;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
}

.filter-date-button-layout {
  position: relative;
  display: flex;
  align-items: center;
}

.date-picker-width {
  width: auto;
}

.date-picker-panel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}