.helper-text {
    font-size: 11px;
    opacity: 0.5;
}

.input-height {
    height: 80px;
}

textarea {
    resize: none;
}

.custom-control-label::before {
    width: 40px !important;
    height: 20px;
}

.custom-control-label::after {
    top: 6px !important;
    width: 15px !important;
    height: 15px !important;

}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: calc(-2.25rem + 10px);
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #0020DB !important;
}

.avatar-btns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}
