.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.sub-title-dark {
  font-size: 20px;
  font-weight: 500;
  color: whitesmoke;
}

.sub-point {
  font-size: 16px;
  font-weight: 400;
  color: #585B5b;
}

.sub-point-dark {
  font-size: 16px;
  font-weight: 400;
  color: whitesmoke;
}

.setting-block {
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 10px;
}

.btn-continue {
  background: rgb(0, 32, 212);
  background: linear-gradient(261deg, rgba(0, 32, 212, 1) 65%, rgba(38, 114, 247, 1) 100%);
  color: whitesmoke !important;
}
.dropdown-account {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #212529 !important;
  font-weight: 400 !important;
  padding: 0 !important;
}

.dropdown-account-dark {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: whitesmoke !important;
  font-weight: 400 !important;
  padding: 0 !important;
}

.dropdown-account-dark:hover,
.dropdown-account-dark:focus {
  background-color: transparent !important;
  color: whitesmoke !important;
  box-shadow: none !important;
}

.dropdown-account:hover,
.dropdown-account:focus {
  background-color: transparent !important;
  color: #212529 !important;
  box-shadow: none !important;
}

.dropdown-item {
  font-weight: 400 !important;
}

.btn-outlined {
  border: solid 1px rgb(0, 32, 212);
}

.btn-outlined:hover {
  background-color: rgb(0, 32, 212);
  color: whitesmoke !important;
}

.dropdown-currency-menu {
  height: 300px;
  overflow-y: scroll;
}

.dropdown-menu {
  background: whitesmoke;
  color: #000000;
}

.dropdown-menu-dark,
.dropdown-menu-dark:hover,
.dropdown-menu-dark:focus,
.dropdown-menu-dark:active {
  background: #1D253D;
  color: whitesmoke;
}

.dropdown-menu-item-dark,
.dropdown-menu-item-dark:hover,
.dropdown-menu-item-dark:focus,
.dropdown-menu-item-dark:active {
  background: #1D253D;
  color: whitesmoke;
}



