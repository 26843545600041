.xt-wallet-details {
  margin-left: 20px;
  padding: 5px;
  background: rgb(255 189 64);
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.xt-button {
  background: linear-gradient(to right, rgb(255 203 64), rgb(255, 189, 64));
  border-radius: 15px;
  width: 50px;
  height: 50px;
}

.bg-text {
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.4);
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 70%;
  padding: 10px;
  text-align: center;
}

.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
