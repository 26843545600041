.form-control {
  height: 45px;
  box-shadow: 1px 1px 12px 3px rgba(0, 0, 0, 0.1);
  border: none !important;
  border-radius: 15px;
  font-weight: 600;
}

.country-list {
  padding: 10px;
  display: flex;
  cursor: pointer;
  transition-duration: 0.2s;

  img {
    object-fit: cover;
    border-radius: 50%;
  }
}

.country-list:hover {
  background: #f5f5f5;
}

.list-cont {
  max-height: 400px;
  overflow: auto;
}