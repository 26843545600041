@media only screen and (max-width: 768px) {
  .min-h-full {
    min-height: auto !important;
  }

  .m-head {
    justify-content: left;

    .price {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      text-align: left;
      font-size: 31px !important;
    }

    .price-p {
      margin-top: 10px;
      margin-bottom: 10px;

      .txt-sm {
        font-size: 15px;
      }
    }
  }

  .col {
    width: 100%;
    flex-basis: fit-content;
  }

  .container {
    padding: 10px !important;
  }

  .btn-def {
    font-size: 13px;
  }

  .p-tik {
    width: 100%;
    margin-top: 20px;
  }

  .coin-info {
    font-weight: bold;
    justify-content: center;

    .block-c {
      flex: 1 1 auto !important;
      padding-left: 20px;
      padding-right: 20px;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      padding-bottom: 20px;
      max-width: 50%;
      min-width: 50%;
    }

    .title {
      font-size: 13px;
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.53);
      font-weight: 700;
    }

    .value {
      margin-bottom: 0;
      margin-top: 0 !important;
    }

    .change {
      margin-bottom: 0;
    }
  }
}

.buy-btn {
  background: #4d8457;
  border: none;
}

.sell-btn {
  background: #ff3847;
  border: none;
}